import Vue from "vue";
import Vuex from "vuex";
import i18n, { selectedLocale } from "./i18n";
import router from "./router";
import LogRocket from "logrocket";
import { getAccessToken } from "./utils";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    InvoiceFIlterStats: {
      count: 0,
      total_paid: 0,
      total_unpaid: 0,
      total_worth: 0,
    },
    crm: {
      switch: false,
    },
    idleTimer: null,
    CurrenctInvoiceFilter: null,
    showOnboarding: false,
    show_quote_share_sheet: false,
    show_share_sheet: false,
    deletedItems: [],
    signalCardCreated: false,
    SignalPaymentChanges: false,
    showSuccess: false,
    requestGeneralPIN: false,
    showDateBalanceBottomSheet: false,
    loadingDateBalances: false,
    DateBalanceData: null,
    DateBalanceType: "",
    DateBalanceDate: "",
    BankCashMonthData: null,
    BankCashMonthDataMonthName: "",
    ShowBankCashMonthBottomSheet: false,
    loadingBankCashMonthData: false,
    total_wallet_spent: 0,
    total_wallet_received: 0,
    invoice_discount_amount: 0,
    invoice_discount_percent: 0,
    secondUnrealisedGains: 0,
    showShortcut: true,
    activeInvoiceTab: "alltab",
    dashboardMonthData: null,
    dashboardMonthDataState: 1,
    loadingMonthData: false,
    wallet: null,
    invoiceSaved: false,
    loadingAssets: false,
    email_rules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    requiredRule: [(v) => !!v || "This field is required"],
    networkStatus: {
      message: "",
      timeOut: -1,
      color: "green",
      show: false,
      icon: "mdi-network-strength-4",
    },
    mono: {
      startDialog: false,
      infor: null,
      progress: true,
      step: 1,
    },
    momo: {
      failed: false,
      message: "",
      progress: false,
      requestPendding: false,
      complete: false,
      failed2: false,
      message2: "",
      progress2: false,
      requestPendding2: false,
      complete2: false,
    },
    getting_rate: false,
    invoiceSideMenu: true,

    ledger_filter: {
      from_date: "",
      to_date: "",
      selected_accounts: "",
      selected_tags: "",
    },
    side_bar: true,
    mediaLinks: [],
    accountType: "",
    accountTransactions: [],
    accountId: null,
    totalBalance: 0,
    generalMessage: "",
    subscriptionForbidden: false,
    subscriptionForbiddenMessage: "",
    showGeneralMessage: false,
    realisedGains: 0,
    unrealisedGains: 0,
    unrealisedGainsPayable: 0,
    realisedgainsChanged: false,
    language: "en",
    invoice_state_changed: false,
    bill_paymentHistory: false,
    quotes_loading: false,
    base_invoice_currency: "",
    fx_invoice_currency: "",
    invoice_fx_rate: 1,
    global_error: false,
    global_error_message: "",
    total_expenses: 0,
    idle_dialog: false,
    newcashexpense: false,
    new_employee: false,
    new_payrun: false,
    empty: false,
    bill_empty: false,
    quote_empty: false,
    expense_empty: false,
    new_transfer: false,
    first_payroll: false,
    pos_dialog: false,
    bill_amount_paid: 0,
    bill_payment_dialog: false,
    checkout_in_production: true,
    paystackkey: "pk_live_7cd806cab1901f4ee5e8a838059dbe9926e0cbd5",
    paystackkey_alt: "pk_test_b30fc5741e33a63b84b5a3e6017fb82640c627d2",
    flutterwave_key: "FLWPUBK-156ac1734b17652dfd246eaca2ae41cd-X",
    flutterwave_keyA: "FLWPUBK_TEST-4105a671a4bcb87e63bec80792b076f3-X",
    invoice_filter_summary: false,
    filtering_invoices: false,
    invoices_per_page: 20,
    quotes_per_page: 20,
    invoiceCurrencySymbol: "",
    filter_applied: false,
    new_item: false,
    new_customer: false,
    new_supplier: false,
    new_income: false,
    filter_dialog: false,
    searching_invoice: false,
    searching_quote: false,
    invoice_searchTerm: "",
    quote_searchTerm: "",
    total_allBills: 0,
    total_awaitingBills: 0,
    total_overdueBills: 0,
    total_paidBills: 0,
    total_allInvoices: 0,
    total_awaitingInvoices: 0,
    invoice_filter_values: null,
    total_overdueInvoices: 0,
    total_paidInvoices: 0,
    current_year: null,
    max_year: null,
    min_year: null,
    progress: false,
    not_verified: false,
    user_initialised: false,
    account: {},
    account_transactions: { data: [] },
    amount_due: 0,
    billwithholding_dialog: false,
    withholding_dialog: false,
    removewithholding_dialog: false,
    removebillwithholding_dialog: false,
    dark_mode: false,
    initializing_app: false,
    balance_sheet: {},
    balance_sheet_date: "",
    init_state: 0,
    countries: [],
    country: null,
    currencies: [],
    currency: null,
    industries: [],
    industry: null,
    incomes: [],
    incomes_empty: false,
    deposits_empty: false,
    min_date: null,
    max_date: null,
    journal: [],
    opening_balances: [],
    ledger: [],
    expenses: [],
    payment_dialog: false,
    add_journal_dialog: false,
    edit_invoice: false,
    invoice_dialog: false,
    baseURL: "https://fxweb.builtaccounting.com",
    add_open_balance_dialog: false,
    loading: false,
    selected_invoice: {},
    invoice_items: [],
    customer: {},
    quotes: [],
    offers: [],
    invoice_settings: {},
    user: {
      businesses: {},
      access_token: "",
      token_expires_on: "",
      token_beerer: "",
      is_loged_in: false,
      user_infor: {},
      countryInfo: {},
    },
    all_invoices: [],
    awaiting_invoices: [],
    overdue_invoices: [],
    paid_invoices: [],
    all_bills: [],
    awaiting_bills: [],
    overdue_bills: [],
    paid_bills: [],
    other_expenses: [],
    product: {},
    supplier: {},
    item_inventory: [],
    customers: [],
    current_business: {
      name: "",
      address: "",
      amail: "",
      location: "",
      phone_number: "",
      bank_name: "",
      account_name: "",
      account_number: "",
      is_retail: "",
      is_service: "",
      logo: "",
      active: "",
    },
    locale: selectedLocale,
    showDepositShareSheet: false,
  },
  actions: {
    ["GET_INVOICES_WITH_FILTERS"]({ commit, state }) {
      if (state.invoice_filter_values) {
        state.loading = true;
        commit("filter_invoices", state.invoice_filter_values);
      } else {
        if (state.activeInvoiceTab === "alltab") {
          commit("get_invoices");
        } else if (state.activeInvoiceTab === "awaitingtab") {
          commit("get_awaitinginvoices");
        } else if (state.activeInvoiceTab === "overduetab") {
          commit("get_overdueinvoices");
        } else if (state.activeInvoiceTab === "paidtab") {
          commit("get_paidinvoices");
        } else {
          commit("get_invoices");
        }
      }
    },
    ["CHANGE_LOCALE"]({ commit }, newLocale) {
      i18n.locale = newLocale;
      commit("UPDATE_LOCALE", newLocale);
    },
    ["CHANGE_INVOICE_CURRENCY"]({ commit }, payload) {
      commit("SET_CURRENCY_SYMBOL", payload);
    },
    ["SET_MODAL_STATE"]({ commit }, payload) {
      commit("UPDATE_MODAL_STATE", payload);
    },
    ["GET_OFFERS"]({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/serviceproviders")
          .then(({ data }) => {
            commit("SET_OFFERS", data);
            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
    ["GET_OFFER_BY_ID"](_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/serviceproviders/${payload.id}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
    ["SEND_APPLICATION"](_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`api/requestfunds`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
  },
  mutations: {
    UPDATE_MODAL_STATE(state, { name, value }) {
      state[name] = value;
    },
    SET_CURRENCY_SYMBOL(state, payload) {
      state.invoiceCurrencySymbol = payload;
    },
    UPDATE_LOCALE(state, locale) {
      state.locale = locale;
    },

    SET_OFFERS(state, payload) {
      state.offers = payload;
    },
    filter_invoices(state, payload) {
      state.CurrenctInvoiceFilter = payload;

      const URL = payload.page
        ? "/api/filterinvoice?page=" + payload.page
        : "/api/filterinvoice?page=1";

      axios
        .post(URL, payload)
        .then((res) => {
          state.all_invoices = res.data.data;
          state.InvoiceFIlterStats = res.data.stats;
          state.filter_dialog = false;
          state.filtering_invoices = false;
          state.invoices_per_page = res.data.data.per_page;
          state.filter_applied = true;
          state.invoice_filter_summary = true;
          state.loading = false;
        })
        .catch((error) => {});
    },
    get_account_transactions(state) {
      state.loading = true;

      axios
        .get("/api/v2/gettransactions/" + state.accountId)
        .then((res) => {
          state.accountTransactions = res.data;
          state.loading = false;
        })
        .catch((err) => {
          state.loading = false;
        });
    },
    init_user(state) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${getAccessToken()}`,
      };

      axios
        .get("/api/auth/user")
        .then((user) => {
          //state.user.is_loged_in = true;
          // state.access_token = localStorage.access_token;
          state.user.user_infor = user.data;

          //this.$router.push({path:'/'});
        })
        .catch(() => {
          state.user.is_loged_in = false;
        });
    },

    search_invoice(state) {
      state.searching_invoice = true;
      router.replace({ path: "/income/invoices" + "?page=" + 1 });
      if (!state.invoice_searchTerm) {
        this.commit("get_invoices");
        state.searching_invoice = false;
        return false;
      }

      axios
        .get("/api/searchinvoice?term=" + state.invoice_searchTerm)
        .then((res) => {
          state.invoices_per_page = res.data.length;

          state.all_invoices.data = res.data;
          state.searching_invoice = false;
        });
    },

    search_quote(state) {
      state.searching_quote = true;
      router.replace({ path: "/income/quotes" + "?page=" + 1 });
      if (!state.quote_searchTerm) {
        this.commit("get_quotes");
        state.searching_quote = false;
        return false;
      }

      axios
        .get("/api/searchquote?term=" + state.quote_searchTerm)
        .then((res) => {
          state.quotes_per_page = res.data.length;

          state.quotes = res.data;
          state.searching_quote = false;
        });
    },
    get_customers(state) {
      axios
        .get("/api/invoiceitems")
        .then((res) => {
          state.customers = res.data;
        })
        .catch(() => {});
    },
    get_invoices(state, url) {
      state.loading = true;
      // state.filter_applied = false;
      let page = "";
      if (url) {
        page = url;
      } else {
        page = router.currentRoute.query.page || 1;
        if (state.invoice_searchTerm) {
          axios
            .get(
              "/api/searchinvoice?term=" +
                state.invoice_searchTerm +
                "&page=" +
                page
            )
            .then((invoices) => {
              state.loading = false;
              state.all_invoices = invoices.data;
              state.invoices_per_page = 20;
              state.empty = invoices.data.data.length === 0;
              state.filter_applied = false
              state.invoice_filter_values = null
            })
            .catch(() => {});
        } else {
          page = "/api/v2/getinvoices?page=" + page;

          axios
            .get(page)
            .then((invoices) => {
              state.loading = false;
              state.all_invoices = invoices.data;
              state.total_allInvoices = invoices.data.total;
              state.invoices_per_page = 20;

              state.empty = invoices.data.data.length === 0;
            })
            .catch(() => {});
        }
      }
    },

    get_awaitinginvoices(state) {
      state.loading = true;

      axios
        .get("/api/v2/getawaitinginvoices")
        .then((invoices) => {
          state.loading = false;
          state.awaiting_invoices = invoices.data;
          state.total_awaitingInvoices = invoices.data.total;
          state.invoices_per_page = 20;
          state.filter_applied = false
          state.invoice_filter_values = null
        })
        .catch(() => {});
    },

    get_customertransactions(state) {
      state.loading = true;
      axios
        .get("/api/gettransactions/" + state.customer.account_id)
        .then((res) => {
          state.customer.transactions = res.data;
          state.loading = false;
        });
    },
    get_overdueinvoices(state) {
      state.loading = true;

      axios
        .get("/api/v2/getoverdueinvoices")
        .then((invoices) => {
          state.overdue_invoices = invoices.data;
          state.total_overdueInvoices = invoices.data.total;
          state.invoices_per_page = 20;
          state.loading = false;
          state.filter_applied = false
          state.invoice_filter_values = null
        })
        .catch(() => {});
    },

    get_paidinvoices(state) {
      state.loading = true;

      axios
        .get("/api/v2/getpaidinvoices")
        .then((invoices) => {
          state.paid_invoices = invoices.data;
          state.total_paidInvoices = invoices.data.total;
          state.invoices_per_page = 20;
          state.loading = false;
          state.filter_applied = false
          state.invoice_filter_values = null
        })
        .catch(() => {});
    },

    get_bills(state) {
      state.loading = true;
      let page = router.currentRoute.query.page
        ? router.currentRoute.query.page
        : 1;
      axios
        .get("/api/v2/allbills?page=" + page)
        .then((bills) => {
          state.all_bills = bills.data.all_bills;
          state.total_allBills = bills.data.all_bills.total;
          state.bill_empty = bills.data.all_bills.data.length === 0;

          state.awaiting_bills = bills.data.awaiting_bills;
          state.total_awaitingBills = bills.data.awaiting_bills.total;

          state.overdue_bills = bills.data.overdue_bills;
          state.total_overdueBills = bills.data.overdue_bills.total;

          state.paid_bills = bills.data.paid_bills;
          state.total_paidBills = bills.data.paid_bills.total;
          state.loading = false;
        })
        .catch(() => {});
    },

    get_awaitingbills(state) {
      state.loading = true;

      axios
        .get("/api/v2/getawaitingbills")
        .then((bills) => {
          state.awaiting_bills = bills.data;
          state.total_awaitingBills = bills.data.total;
          state.loading = false;
        })
        .catch(() => {});
    },

    get_overduebills(state) {
      state.loading = true;

      axios
        .get("/api/v2/getoverduebills")
        .then((bills) => {
          state.overdue_bills = bills.data;
          state.total_overdueBills = bills.data.total;
          state.loading = false;
        })
        .catch(() => {});
    },

    get_paidbills(state) {
      state.loading = true;

      axios
        .get("/api/v2/getpaidbills")
        .then((bills) => {
          state.loading = false;
          state.paid_bills = bills.data;
          state.total_paidBills = bills.data.total;
        })
        .catch(() => {});
    },

    get_quotes(state) {
      state.quotes_loading = true;
      let page = router.currentRoute.query.page || 1;
      if (state.quote_searchTerm) {
        axios
          .get(
            "/api/searchquote?term=" + state.quote_searchTerm + "&page=" + page
          )
          .then((quote) => {
            state.quotes_loading = false;
            state.quotes = quote.data;
            state.quote_empty = quote.data.data.length === 0;
          });
      } else {
        axios
          .get("/api/v2/quote?page=" + page)
          .then((quote) => {
            state.quotes_loading = false;
            state.quotes = quote.data;
            state.quote_empty = quote.data.data.length === 0;
          })
          .catch(() => {});
      }
    },

    get_expenses(state) {
      state.loading = true;
      let page = router.currentRoute.query.page
        ? router.currentRoute.query.page
        : 1;
      axios
        .get("/api/expenses?page=" + page)
        .then((res) => {
          state.loading = false;
          state.expenses = res.data;
          state.expense_empty = res.data.length === 0;
          state.total_expenses = res.data.total;
        })
        .catch(() => {});
    },

    get_otherincomes(state) {
      state.loading = true;

      axios
        .get("/api/otherincomes")
        .then((res) => {
          state.loading = false;
          state.incomes = res.data;
          state.incomes_empty = res.data.length === 0;
        })
        .catch(() => {});
    },

    get_ledger(state, page = 1) {
      state.loading = true;

      axios
        .get("/api/getledger?page=" + page)
        .then((res) => {
          state.ledger = res.data;
          state.loading = false;
        })
        .catch(() => {});
    },

    get_journal(state) {
      state.loading = true;
      let page = router.currentRoute.query.page
        ? router.currentRoute.query.page
        : 1;
      axios.get("/api/getjournal?page=" + page).then((res) => {
        state.journal = res.data;
        state.loading = false;
      });
    },

    updateWallet(state) {
      axios.get("/api/bw/wallet").then((res) => {
        state.wallet = res.data.data;
      });
    },
    get_opening_balances(state) {
      state.loading = true;
      axios.get("/api/getopeningbalances").then((res) => {
        state.opening_balances = res.data;
        state.loading = false;
      });
    },

    switch_ui(state) {
      localStorage.isDark = this.$vuetify.theme.isDark;
    },

    init_app(state) {
      if (state.baseURL === "https://web.builtaccounting.com") {
        LogRocket.init("22rea0/my-project");
      }

      state.initializing_app = true;
      state.user_initialised = false;

      state.init_state = 20;

      if (getAccessToken() != undefined) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${getAccessToken()}`,
        };

        axios
          .get("/api/auth/user")
          .then((user) => {
            state.user_initialised = true;
            state.init_state = 60;
            state.user.is_loged_in = true;
            state.access_token = getAccessToken();
            state.user.user_infor = user.data;

            if (user.data.has_business) {
              localStorage.setItem(
                "business_country",
                state.user.user_infor.current_business.country.code
              );

              state.current_year =
                state.user.user_infor.current_business.current_year;
              state.max_year = state.user.user_infor.current_business.max_year;
              state.min_year = state.user.user_infor.current_business.min_year;

              const loggedInUserId = user.data.id; // example
              window.CommandBar.boot(loggedInUserId).then(() => {
                // ...
              });
            } else {
              state.initializing_app = false;

              state.init_state = 100;
            }
            this.state.invoice_settings = user.data.invoice_settings;

            //this.$router.push({path:'/'});
            if (user.data.has_business) {
              /**
               *
               * if accounts are already cached then load from local storage
               * @type {string}
               */

              // let accounts = localStorage.getItem(
              //     "accounts." + state.user.user_infor.current_business.id
              // );
              // if (Boolean(accounts)) {
              //    state.user.countryInfo = JSON.parse(accounts);
              //    state.init_state = 100;
              //    state.initializing_app = false;
              // } else {
              axios
                .get("/api/v2/getallaccounts")
                .then((res) => {
                  // localStorage.setItem(
                  //     "accounts." + state.user.user_infor.current_business.id,
                  //     JSON.stringify(res.data)
                  // );
                  state.user.countryInfo = res.data;

                  state.init_state = 100;

                  state.initializing_app = false;
                })

                .catch((error) => {});
              /// }
            }
          })

          .catch(() => {
            state.user.is_loged_in = false;
            state.initializing_app = false;
          });
      } else {
        state.user.is_loged_in = false;
        state.initializing_app = false;
      }

      if (state.baseURL === "https://web.builtaccounting.com") {
        LogRocket.identify(state.user.user_infor.email, {
          name:
            state.user.user_infor.first_name +
            " " +
            state.user.user_infor.last_name,
          email: state.user.user_infor.email,
          business: state.user.user_infor.has_business
            ? state.user.user_infor.current_business.name
            : "no business",
          business_country: state.user.user_infor.has_business
            ? state.user.user_infor.current_business.country.name
            : "not available",
        });
      }
    },
  },
});
